<template>
  <fm-modal title="维修申请" v-model="modal" width="800" v-if="modal">
    <div class="p-detial-from">
      <div class="right">
        <!-- <fm-title titleText="采购明细"></fm-title> -->
        <fm-form label-align="left">
          <fm-form-item label="申请科室" v-if="orgList.length > 1">
            <fm-select absolute filterable :clearable="true" v-model="formData.applyOrgId">
              <fm-option v-for="item in orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="是否维修现有资产">
            <fm-select absolute filterable v-model="formData.isAssetsId">
              <fm-option v-for="item in ['是', '否']" :key="item" :value="item" :label="item"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="维修资产" v-if="formData.isAssetsId === '是'">
            <fm-select absolute filterable :clearable="true" v-model="formData.assetsId">
              <fm-option v-for="item in assetsList" :key="item.id" :value="item.id" :label="item.code + item.name"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="物品名称" v-if="formData.isAssetsId === '否'">
            <fm-input-new v-model="formData.assetsName"></fm-input-new>
          </fm-form-item>
          <fm-form-item label="物品价值" v-if="formData.isAssetsId === '否'">
            <fm-input-new v-model="formData.jz"></fm-input-new>
          </fm-form-item>
          <fm-form-item label="关联预算">
            <fm-select absolute filterable :clearable="true" v-model="formData.budgetDetailId">
              <fm-option v-for="item in budgetDetailList" :key="item.id" :value="item.id" :label="item.code + ' ' + item.goodsName"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="预估维修金额">
            <fm-input-new v-model="formData.repairJe"></fm-input-new>
          </fm-form-item>
          <fm-form-item label="维修厂家">
            <fm-input-new v-model="formData.repairCmp"></fm-input-new>
          </fm-form-item>
          <!-- <fm-form-item label="维修内容">
            <fm-input-new type="textarea" v-model="formData.content"></fm-input-new>
          </fm-form-item> -->
          <fm-form-item label="申请理由">
            <fm-input-new type="textarea" v-model="formData.reason"></fm-input-new>
          </fm-form-item>
        </fm-form>
        <div class="model-btn">
          <fm-btn style="margin-right: 30px;" @click="formSubmit">保存</fm-btn>
          <fm-btn @click="modal = false">取消</fm-btn>
        </div>
      </div>
    </div>
  </fm-modal>
</template>

<script>
import {
  assetsRepairApplyRequest
} from '../../../api'

export default {
  props: {
    data: { type: Object, defualt: () => null },
    orgList: { type: Array, defualt: () => [] },
    assetsList: { type: Array, defualt: () => [] },
    budgetDetailList: { type: Array, defualt: () => [] }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        let data = this.data ? JSON.parse(JSON.stringify(this.data)) : {
          isAssetsId: '是'
        }
        data.isAssetsId = data.isAssetsId || (!data.id || data.assetsId ? '是' : '否')
        this.formData = data
      },
      immediate: true
    }
  },
  data () {
    return {
      formData: {},
      searchText: '',
      step: 0,
      modal: false,
      loading: false
    }
  },
  methods: {
    async formSubmit () {
      if (this.loading) {
        return
      }
      if (!this.formData.applyOrgId) {
        if (this.orgList.length === 1) {
          this.formData.applyOrgId = this.orgList[0].key
        } else {
          this.$notify({
            title: '系统提示',
            message: '请选择申请科室',
            type: 'info'
          })
          return
        }
      }
      if (isNaN(this.formData.repairJe)) {
        this.$notify({
          title: '系统提示',
          message: '预估维修金额请输入数值',
          type: 'info'
        })
        return
      }
      if (this.formData.repairJe != null) {
        this.formData.repairJe = this.formData.repairJe.replaceAll(' ', '')
        if (this.formData.repairJe === '') {
          this.formData.repairJe = null
        }
      }
      if (this.formData.isAssetsId === '是') {
        if (!this.formData.assetsId) {
          this.$notify({
            title: '系统提示',
            message: '请选择维修资产',
            type: 'info'
          })
          return
        }
        let assets = this.assetsList.find(v => v.id === this.formData.assetsId)
        this.formData.jz = assets.jz
        this.formData.assetsName = null
      } else {
        if (!this.formData.assetsName) {
          this.$notify({
            title: '系统提示',
            message: '请输入物品名称',
            type: 'info'
          })
          return
        }
        if (isNaN(this.formData.jz)) {
          this.$notify({
            title: '系统提示',
            message: '物品价值请输入数值',
            type: 'info'
          })
          return
        }
        if (this.formData.jz != null) {
          this.formData.jz = this.formData.jz.replaceAll(' ', '')
          if (this.formData.jz === '') {
            this.formData.jz = null
          } else {
            this.formData.jz = Number(this.formData.jz)
          }
        }
        this.formData.assetsId = null
      }
      this.formData.type = this.formData.jz && this.formData.jz > 2000 ? 'norm' : 'mini'
      this.loading = true
      let data = this.formData
      if (data.id) {
        await assetsRepairApplyRequest.update(data.id, data)
      } else {
        await assetsRepairApplyRequest.add(data)
      }
      this.$emit('dataUpdate', 'add')
      this.modal = false
      this.loading = false
    }
  }
}
</script>

<style scoped lang="less">
.p-detial-from {
  display: flex;
  .center {
    max-height: 100%;
    overflow-y: auto;
    width: 380px;
    padding: 0 20px;
  }
  .left {
    flex: 1;
    border-radius: 5px;
  }
  .left-a {
    border: 1px solid #e8eaec;
    border-radius: 5px;
    flex: 1;
    display: flex;
  }
  .right {
    margin-right: 20px;
    width: 100%;
  }
  .purchase-batch-detail-list-search {
    margin-left: 10px;
  }
  .purchase-batch-detail-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    max-height: calc(100% - 80px);
    overflow-y: auto;
  }
  .purchase-batch-detail-item {
    background: #e8eaec;
    color: #515a6e;
    line-height: 28px;
    padding: 0 10px;
    border-radius: 5px;
    margin: 10px 0 0 10px;
  }
  .purchase-batch-detail-item-a {
    background: #F4628F;
    color: #fff;
  }
}
.model-btn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>